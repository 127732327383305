















































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import "simple-syntax-highlighter/dist/sshpre.css";
import ContactMessage, {
    CONTACT_MESSAGE_STATUSES,
    SerializedContactMessage,
} from "@/models/contact-message";
import ContactMessageAPI from "@/services/api/contact-message";
import {
    DatatableFooterProps,
    DefaultFooterProps,
    SelectItem,
} from "@/types/veutify";
import { mdiCheck, mdiClose, mdiMagnify, mdiWhatsapp } from "@mdi/js";
import { captureSentryException } from "@/plugins/sentry";
import titleCase from "@/filters/titleCase";
import { ensureAuthenticated } from "@/plugins/firebase";

@Component
export default class AdminContactMessagesIndex extends Vue {
    loadingContactMessages: boolean = true;
    messages: Array<ContactMessage> = [];
    searchIcon: string = mdiMagnify;
    trueIcon: string = mdiCheck;
    whatsappIcon: string = mdiWhatsapp;
    falseIcon: string = mdiClose;
    query: string = "";
    statuses: Array<SelectItem> = CONTACT_MESSAGE_STATUSES.map(
        (status: string) => {
            return {
                text: titleCase(status),
                value: status,
            };
        }
    );
    editedStatus: string | null = null;
    footerProps: DatatableFooterProps = DefaultFooterProps;
    headers = [
        {
            text: "Message ID",
            value: "id",
        },
        {
            text: "Received At",
            value: "timestamp",
        },
        {
            text: "Phone Number",
            value: "phoneNumber",
        },
        {
            text: "WhatsApp",
            value: "canContactOnWhatsapp",
        },
        {
            text: "Status",
            value: "status",
        },
        {
            text: "User ID",
            value: "userId",
        },
        { text: "Details", value: "data-table-expand" },
    ];

    mounted() {
        ensureAuthenticated()
            .then(() => {
                this.loadContactMessages();
            })
            .catch(() => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.ERROR,
                    "We're having trouble authenticating your account. Please try again later"
                );
            });
    }

    updateEditedStatus(item: ContactMessage) {
        this.editedStatus = item.status;
    }

    loadContactMessages() {
        ContactMessageAPI.adminIndex()
            .then((response: AxiosResponse) => {
                this.messages = response.data.data.map(
                    (message: SerializedContactMessage) => {
                        return new ContactMessage(message);
                    }
                );

                this.loadingContactMessages = false;
            })
            .catch(this.handleAxiosError);
    }

    emitErrorNotification(error: Error) {
        captureSentryException(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleStatusUpdate(messageId: string) {
        this.messages = [
            ...this.messages.map((message: ContactMessage) => {
                if (message.id === messageId) {
                    message.status = this.editedStatus as string;
                }
                return message;
            }),
        ];
        this.updateStatus(messageId, this.editedStatus as string);
        return true;
    }

    updateStatus(messageID: string, status: string) {
        ContactMessageAPI.adminUpdate(messageID, status)
            .then(() => {
                this.$root.$emit(
                    this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                    "Message status has been successfully updated"
                );
                this.loadContactMessages();
            })
            .catch(this.handleAxiosError);
    }

    handleAxiosError(error: AxiosError) {
        this.emitErrorNotification(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }
}
